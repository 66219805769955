<template>
  <v-container>
    <v-row>
      <v-col cols="6" v-for="m in menues" :key="m.path">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" :elevation="hover ? 6 : 2" :to="{ path: m.path }">
            <v-card-title>
              <v-icon large left v-html="m.icon" />
              <span class="headline">{{ m.title }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="font-weight-light">
              {{ m.description }}
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    menues() {
      return [
        {
          path: 'signup_requests',
          icon: 'history_edu',
          title: 'Signup Request',
          description: this.$t('menu.signup_request'),
        },
        {
          path: 'create_bpo',
          icon: 'store',
          title: 'New Unit',
          description: this.$t('menu.new_unit_subtitle'),
        },
        {
          path: 'create_manager',
          icon: 'perm_identity',
          title: 'New Annotation Manager',
          description: this.$t('menu.new_am_subtitle'),
        },
        {
          path: 'attachment',
          icon: 'attachment',
          title: 'BPO Attachment',
          description: this.$t('menu.bpo_attachment_subtitle'),
        },
        {
          path: 'users',
          icon: 'supervised_user_circle',
          title: this.$t('users.user_management'),
          description: this.$t('menu.user_mgt_subtitle'),
        },
        {
          path: 'announcements',
          icon: 'announcement',
          title: 'Announcements',
          description: 'System announcements',
        },
        {
          path: 'clients',
          icon: 'grade',
          title: 'Clients',
          description: 'Client Managements',
        },
        {
          path: 'templates',
          icon: 'note',
          title: 'Templates',
          description: 'Annotation template management',
        },
        {
          path: 'performance',
          icon: 'adjust',
          title: 'Performance',
          description: 'Update Peformance',
        },
      ]
    },
  },
}
</script>
